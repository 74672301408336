import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@mui/material";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { InputText, UncontrolledSwitch } from "../../formInputs/Inputs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  MainPageCard,
  FormDiv,
  SecondaryTitleText,
  FourthTitleText,
} from "../../../helpers/generalStyles";
import Locker from "../Locker";
import {
  ActivityPrices,
  activityPrices,
  upsertActivity as apiUpsertActivity,
} from "../../../backend/api/activities";
import { useMutation } from "react-query";
import { queryClient } from "../../..";
import AlertMessage from "../../general/AlertMessage";
import { useEffect, useMemo, useState } from "react";
import { Message } from "../../../helpers/helpers";
import {
  ActivityPricesStatusEnum,
  ActivityPricesTypeEnum,
} from "helpers/constants";

export function PricesForm(props: {
  inputData: ActivityPrices;
  activityId: number | null;
  isLockerOpen: boolean;
  setIsLockerOpen: any;
  navigate: any;
  t: any;
  isGroupActivity: boolean;
}) {
  const {
    inputData,
    activityId,
    isLockerOpen,
    setIsLockerOpen,
    navigate,
    t,
    isGroupActivity,
  } = { ...props };
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  useEffect(() => {
    setAlertMessage({ message: "", type: "success" });
  }, [inputData]);

  const isAddPriceActive = useMemo(
    () => !isGroupActivity || inputData.prices.length === 0,
    [inputData, isGroupActivity],
  );

  const mutateActivity = useMutation({
    mutationFn: (data: ActivityPrices) =>
      apiUpsertActivity({
        ...data,
        activityId: activityId ? String(activityId) : null,
      }),
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesWithDisabled"],
      });
      setIsLockerOpen(false);
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(activityPrices),
    values: inputData,
  });

  const onSubmit: SubmitHandler<any> = (data: ActivityPrices) => {
    mutateActivity.mutate(data);
  };

  const { fields, append, update, replace } = useFieldArray({
    control,
    name: "prices",
    keyName: "_id",
  });

  return (
    <MainPageCard>
      <Locker
        isOpen={isLockerOpen}
        onClick={() => setIsLockerOpen(!isLockerOpen)}
      />
      <SecondaryTitleText>
        {t("pricesSubTitle").toUpperCase()}
      </SecondaryTitleText>
      {/* cause FormDiv have minWidth: 400px and it's too much for here */}
      <FormDiv style={{ minWidth: "200px" }} onSubmit={handleSubmit(onSubmit)}>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "solid 1px #f2f2f2",
            alignItems: "center",
            gap: "20px",
            maxHeight: "250px",
            width: "100%",
            overflowY: "scroll",
            paddingTop: "10px",
          }}
        >
          {fields.map((field: any, index) => {
            const isPriceEnabled =
              field.status === ActivityPricesStatusEnum.ENABLED;
            const isPriceDisabled =
              field.status === ActivityPricesStatusEnum.DISABLED;
            const currentPrice = watch().prices[index];
            return (
              <div
                key={field._id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "20px",
                  width: "100%",
                  borderBottom: "solid lightGrey 1px",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {currentPrice.type ? (
                    <>
                      <FourthTitleText style={{ margin: "0px" }}>
                        {t(`priceName.${currentPrice.type}`)}
                      </FourthTitleText>
                      <div style={{ color: "gray", fontWeight: "100" }}>
                        {currentPrice.type === ActivityPricesTypeEnum.DEFAULT
                          ? t("mandatoryPriceTypeSubTitle")
                          : t("optionnalPriceTypeSubTitle")}
                      </div>
                    </>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <InputText
                      hide={currentPrice.type ? true : false}
                      id={`prices.${index}.name`}
                      name={`prices.${index}.name`}
                      label={t("priceType")}
                      required
                      disabled={!isLockerOpen || isPriceDisabled}
                      control={control}
                      error={
                        errors.prices
                          ? errors?.prices[index]?.name?.message
                          : null
                      }
                      t={t}
                    />
                    <InputText
                      id={`prices.${index}.price`}
                      name={`prices.${index}.price`}
                      label={t("price")}
                      inputType={"decimal"}
                      required
                      disabled={!isLockerOpen || isPriceDisabled}
                      control={control}
                      error={
                        errors.prices
                          ? errors?.prices[index]?.price?.message
                          : null
                      }
                      t={t}
                    />
                    <InputText
                      id={`prices.${index}.description`}
                      name={`prices.${index}.description`}
                      label={t("description")}
                      disabled={!isLockerOpen || isPriceDisabled}
                      control={control}
                      error={
                        errors.prices
                          ? errors?.prices[index]?.description?.message
                          : null
                      }
                      t={t}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <UncontrolledSwitch
                      key={index}
                      isActive={isPriceEnabled}
                      onChange={() =>
                        isPriceEnabled
                          ? update(index, {
                              ...field,
                              id: Number(field.id),
                              status: ActivityPricesStatusEnum.DISABLED,
                            })
                          : update(index, {
                              ...field,
                              id: Number(field.id),
                              status: ActivityPricesStatusEnum.ENABLED,
                            })
                      }
                      disabled={!isLockerOpen}
                      t={t}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {isAddPriceActive && (
            <AddCircleOutlineIcon
              style={{
                color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                cursor: "pointer",
              }}
              onClick={() =>
                isLockerOpen
                  ? append({
                      name: "",
                      price: 1,
                      description: null,
                      status: ActivityPricesStatusEnum.ENABLED,
                    })
                  : console.log("locker clos")
              }
            />
          )}
        </div>
        <Button
          disabled={!isLockerOpen || mutateActivity.isLoading}
          color="info"
          variant="contained"
          type="submit"
        >
          {t("save")}
        </Button>
      </FormDiv>
    </MainPageCard>
  );
}
