import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navigation/Navbar";
import MainTitle from "../components/general/MainTitle";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Message } from "../helpers/helpers";
import { MainPageCard } from "../helpers/generalStyles";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Activity,
  ActivityAvailabilities,
  Availability,
  getActivities,
  getAvailabilities,
} from "../backend/api/activities";
import AlertMessage from "../components/general/AlertMessage";
import { PostBookingInput, postBooking } from "../backend/api/bookings";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import {
  BookingFormValidator,
  bookingFormValidator,
} from "../components/bookings/bookingFormGroups/bookingFormValidator";
import BookingFormGroup1 from "../components/bookings/bookingFormGroups/BookingFormGroup1";
import BookingFormGroup2 from "../components/bookings/bookingFormGroups/BookingFormGroup2";
import BookingFormGroup3 from "../components/bookings/bookingFormGroups/BookingFormGroup3";
import {
  ActivityPricesStatusEnum,
  PlatformTypeEnum,
} from "../helpers/constants";

export default function Bookings() {
  const { t } = useTranslation("bookings");
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [activities, setActivities] = useState<Activity[]>([]);

  const [monthAvailabilities, setMonthAvailabilities] =
    useState<ActivityAvailabilities>([]);
  const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment());
  const [selectedMonth, setSelectedMonth] = useState<number>(moment().month());

  const [availability, setAvaibility] = useState<Availability>();
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  // begin form variables
  const initialFormValues: BookingFormValidator = {
    activityId: searchParams.get("activityId")
      ? String(searchParams.get("activityId"))
      : "",
    date: searchParams.get("day") ? moment(searchParams.get("day")) : moment(),
    slots: [],
    prices: [],
    fullName: "",
    email: "",
    phone: "",
  };

  const form = useForm({
    resolver: zodResolver(bookingFormValidator),
    defaultValues: initialFormValues,
  });

  const values = form.getValues();
  const watch = form.watch();

  const formSlots = useFieldArray({
    control: form.control,
    name: "slots",
  });

  const formPrices = useFieldArray({
    control: form.control,
    name: "prices",
  });

  const onSubmit: SubmitHandler<any> = (data: BookingFormValidator) => {
    const mutateObject = {
      source: PlatformTypeEnum.HOPLEISURE as "HOPLEISURE" | "WIDGET",
      visitorInfos: {
        fullName: `${data.fullName}`,
        email: data.email,
        phoneNumber: data.phone,
      },
      bookings: data.prices.map((price: any) => {
        return {
          numberOfUnit: Number(price.number),
          priceName: price.name,
          from: `${data.date.format("YYYY-MM-DD")} ${data.slots[0].from}`,
          to: `${data.date.format("YYYY-MM-DD")} ${data.slots[0].to}`,
        };
      }),
    };
    mutateBooking.mutate(mutateObject);
  };
  // end form variables

  const activityId = values.activityId;
  const activity = useMemo(
    () => activities.find((item) => item.id === Number(activityId)),
    [activities, activityId],
  );

  useEffect(() => {
    if (activity?.prices) {
      const enabledPrices = activity?.prices
        .map((item) => {
          return {
            number: 0,
            name: item.name,
            price: Number(item.price),
            status: item.status,
            type: item.type || undefined,
          };
        })
        .filter((price) => price.status === ActivityPricesStatusEnum.ENABLED);
      formPrices.replace(enabledPrices);
    }
  }, [activity]);

  useEffect(() => {
    const activityId = watch.activityId;
    if (activityId) {
      updateMonthAvailabilities(selectedMonth, activityId);
    } else setMonthAvailabilities([]);
  }, [watch.activityId, selectedMonth]);

  useEffect(() => {
    formSlots.replace([]);
    // add page in dependencies cause we want to update availabilities when submit form and come back to page one on page three
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch["activityId"], watch["date"]]);

  useEffect(() => {
    const pricesWithNbOfUnitsReseted = formPrices.fields.map((item) => {
      return { ...item, number: 0 };
    });
    formPrices.replace(pricesWithNbOfUnitsReseted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availability]);

  // API calls
  useQuery({
    queryKey: ["getActivities"],
    queryFn: () => getActivities(),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setActivities(data);
    },
  });

  // #TODO: convert into useQuery call
  const updateMonthAvailabilities = async (
    month: number,
    activityId: string,
  ) => {
    const date = moment({ month: month });
    try {
      const data: any = await getAvailabilities(
        Number(activityId),
        date.startOf("month").format("YYYY-MM-DD"),
        date.endOf("month").format("YYYY-MM-DD"),
      );
      if (data?.message) setMonthAvailabilities([]);
      else {
        setMonthAvailabilities(data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const mutateBooking = useMutation({
    mutationFn: (data: PostBookingInput["body"]) =>
      postBooking(Number(activityId), data),
    onSuccess: (result) => {
      setAlertMessage({ type: "success", message: t("success") });
      setPage(1);
    },
    onError: (error: any) => {
      console.log(error);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });

  return (
    <Page>
      <Navbar />
      <Container>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <MainTitle title={t("bookingsTitle")} />
        <MainPageCard style={{ height: "100%" }}>
          <BookingForm onSubmit={form.handleSubmit(onSubmit)}>
            {page === 1 &&
              BookingFormGroup1({
                form: form,
                formSlots,
                activities,
                setAvaibility,
                monthAvailabilities,
                selectedDay,
                setSelectedDay,
                setSelectedMonth,
                setPage,
                t,
              })}
            {activity &&
              availability &&
              page === 2 &&
              BookingFormGroup2({
                form: form,
                formPrices,
                activity: activity,
                availability,
                numberOfTicketsInTheBooking: 0,
                setPage,
                t,
              })}
            {activity &&
              page === 3 &&
              BookingFormGroup3({ form: form, activity, setPage, t })}
          </BookingForm>
        </MainPageCard>
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100%;
  position: relative;
  background-color: #e0e1e2;
`;

const Container = styled.div`
  position: relative;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
`;

const BookingForm = styled.form`
  position: relative;
  padding-right: 200px;
  padding-left: 200px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;
