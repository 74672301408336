import { Chip } from "@mui/material";
import { categoryImageMapping } from "../../helpers/constants";

export default function ActivityType(props: {
  title: string;
  selected: boolean;
  type: string;
  disabled?: boolean;
  onClick: any;
}) {
  const { title, selected, type, onClick, disabled } = props;
  return (
    <Chip
      icon={categoryImageMapping(type)}
      label={title}
      color={!disabled ? "success" : undefined}
      onClick={onClick}
      variant={selected ? "filled" : "outlined"}
      size="medium"
      sx={{
        margin: "10px",
        // padding: "20px",
        backgroundColor: !disabled
          ? selected
            ? "#2A58D"
            : "#E1F9F3"
          : "lightgray",
        color: selected ? "#FFF" : "#000000",
      }}
    />
  );
}
